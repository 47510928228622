<template>
    <div>
        <appic-page-title-bar ref="breadcrumbBar"/>
        <app-section-loader :status="loader"/>
        <v-container fluid grid-list-xl pb-0 pt-3 px-3>
            <v-layout row>
                <app-card :fullBlock="true" colClasses="xl12 lg12 md12 sm12 xs12">
                    <v-flex class="col-12 col-xl-7 align-center px-1 pb-0 pt-0">
                        <v-sheet class="dense-inputs">
                            <div class="d-flex align-center flex-md-wrap">
                                <v-autocomplete
                                    :items="filterItems.user"
                                    :loading="loading.filterItems.users"
                                    :placeholder="$t('message.user')"
                                    autocomplete="new-password"
                                    class="filter-width-200"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-text="User.title"
                                    item-value="User.uuid"
                                    solo
                                    v-model="filterValues.user"
                                    @change="filterUserLog()"
                                />
                                <v-spacer/>
                                <v-btn
                                    :loading="loading.clear"
                                    class="px-2"
                                    @click="clearFilters"
                                >{{ $t('message.clear') }}</v-btn>
                            </div>
                        </v-sheet>
                        <div style="position: relative">
                            <v-overlay
                                    :value="loading.logs"
                                    absolute
                                    opacity="0.15"
                            >
                                <v-row>
                                    <v-col class="text-center">
                                        <v-progress-circular
                                                color="primary"
                                                indeterminate
                                                size="40"
                                                width="6"
                                        />
                                    </v-col>
                                </v-row>
                            </v-overlay>
                            <v-data-table
                                    :footer-props="{
                                        itemsPerPageOptions: itemsPerPageOptions,
                                        showFirstLastPage: true
                                    }"
                                    :headers="headers"
                                    :items="allRows"
                                    :options="tableOptions"
                                    :server-items-length="totalRows"
                                    calculate-widths
                                    class="mt-3 appic-table-light specification-table"
                                    dense
                                    id="userLogTable"
                                    item-key="UserLog.id"
                                    sort-by="UserLog.timestamp"
                                    sort-desc
                                    @update:options="updateDataTable"
                            >
                                <template v-slot:item.UserLog.timestamp="{ item }">
                                    <span class="text-no-wrap">{{ item.UserLog.timestamp }}</span>
                                </template>
                                <template v-slot:item.UserLog.user="{ item }">
                                    <span class="font-weight-bold text-no-wrap">{{ capitalize(item.UserLog.user) }}</span>
                                </template>
                                <template v-slot:item.UserLog.document="{ item }">
                                    <span class="text-no-wrap">{{ item.UserLog.document ? item.UserLog.document : $t('message.na') }}</span>
                                </template>
                            </v-data-table>
                        </div>
                    </v-flex>
                </app-card>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import {capitalize} from "Helpers/helpers";
import {api} from "Api";
import {mapFields} from "vuex-map-fields";
import {mapActions} from "vuex";

export default {
    name: "UserLog",
    data() {
        return {
            allRows: [],
            filterItems: {
                user: [],
            },
            filterValues: {
                user: null,
            },
            loader: false,
            loading: {
                filterItems: {
                    user: false,
                },
                search: false,
                logs: false
            },
            tableOptions: {},
            totalRows: 0,
        }
    },
    computed: {
        ...mapFields('user',{
            allUsers: 'allUsers'
        }),
        defaultItemsPerPage() {
            if(window.screen.height >= 800) return 20
            return 15
        },
        headers() {
            return [
                {
                    id: 1,
                    text: this.$t('message.timestamp'),
                    value: 'UserLog.timestamp',
                    class: 'light-green lighten-3 pa-1 width-1-pct',
                    sortable: true
                },
                {
                    id: 2,
                    text: this.$t('message.user'),
                    value: 'UserLog.user',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 3,
                    text: this.$t('message.action'),
                    value: 'UserLog.event',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 4,
                    text: this.$t('message.document'),
                    value: 'UserLog.document',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 5,
                    text: this.$t('message.ip'),
                    value: 'UserLog.ip',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 6,
                    text: this.$t('message.approxLocation'),
                    value: 'UserLog.location',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                }
            ]
        },
        itemsPerPageOptions() {
            let options = [15,30,50,100];
            if(window.screen.height >= 800){
                options = [20,40,60,150];
            }
            return options;
        },
    },
    methods: {
        ...mapActions('user',{
            getAllUsers: 'getAllUsers',
            resetAllUsers: 'resetAllUsers',
        }),
        capitalize,
        clearFilters() {
            this.filterValues.user = null
            this.getUserActivity()
        },
        filterUserLog() {
            let noValues = true
            const keys = Object.keys(this.filterValues)
            keys.forEach((key, index) => {
                if(this.filterValues[key] != null) noValues = false
            })
            if(noValues == false) {
                let conditions = []
                if (this.filterValues.user != null) conditions.push({
                    field: 'User.uuid',
                    value: this.filterValues.user
                })
                if(this.tableOptions.itemsPerPage == null) this.tableOptions.itemsPerPage = this.defaultItemsPerPage

                if(_.has(this.tableOptions, 'sortBy') == false) {
                    this.tableOptions['sortBy'] = ['UserLog.timestamp']
                }

                if(this.tableOptions.sortBy.length == 0){
                    this.tableOptions['sortBy'] = ['UserLog.timestamp']
                }
                if(_.has(this.tableOptions, 'sortDesc') == false){
                    this.tableOptions['sortDesc'] = [false]
                    if(this.tableOptions.sortBy[0] == 'UserLog.timestamp') {
                        this.tableOptions['sortDesc'] = [true]
                    }
                }
                if(this.tableOptions.sortBy[0] == 'UserLog.timestamp' && this.tableOptions.sortDesc.length == 0){
                    this.tableOptions['sortDesc'] = [true]
                }
                let order = [{field: this.tableOptions.sortBy[0], direction: this.tableOptions.sortDesc[0] ? 'DESC' : 'ASC'}]

                this.hideDefaultFooter = false
                this.loading.log = true;
                api
                    .get('/admin/user-log',{
                        params: {
                            conditions: conditions,
                            pagination: {
                                skip: (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage,
                                take: this.tableOptions.itemsPerPage
                            },
                            order: order
                        }
                    })
                    .then((response) => {
                        this.allRows = response.data.data
                        this.totalRows = response.data.totalRows
                        this.loading.log = false;
                    })
                    .catch((error) => {
                        this.loading.log = false;
                    })
            } else {
                this.getUserActivity()
            }
        },
        getUserActivity() {
            if(this.tableOptions.page != null) {
                this.loading.logs = true
                api
                    .get('/admin/user-log', {
                        params: {
                            pagination: {
                                skip: (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage,
                                take: this.tableOptions.itemsPerPage
                            },
                            order: [
                                {
                                    field: 'UserLog.timestamp',
                                    direction: 'DESC'
                                }
                            ]
                        }
                    })
                    .then((response) => {
                        if (response.data.status == 'success') {
                            this.allRows = response.data.data
                            this.totalRows = response.data.totalRows
                        } else {
                            this.$toast.error(this.$t('message.errors.logNotLoaded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                        this.loading.logs = false
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.logNotLoaded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.logs = false
                    })
            }
        },
        updateDataTable(options) {
            this.tableOptions = options
            this.filterUserLog()
        },
    },
    mounted() {
        this.resetAllUsers()
        if(this.allUsers.length == 0) {
            this.getAllUsers()
                .then(() => {
                    this.filterItems.user = this.allUsers.filter(user => {
                        return user.User.userstatus_id == 1
                    })
                })
        }
        this.getUserActivity()
    }
}
</script>

<style>
.filter-width-200 {
    width: 200px !important;
    max-width: 200px !important;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.v-data-table td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
.v-data-table th {
    white-space: nowrap;
}
</style>