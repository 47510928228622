var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('appic-page-title-bar',{ref:"breadcrumbBar"}),_c('app-section-loader',{attrs:{"status":_vm.loader}}),_c('v-container',{attrs:{"fluid":"","grid-list-xl":"","pb-0":"","pt-3":"","px-3":""}},[_c('v-layout',{attrs:{"row":""}},[_c('app-card',{attrs:{"fullBlock":true,"colClasses":"xl12 lg12 md12 sm12 xs12"}},[_c('v-flex',{staticClass:"col-12 col-xl-7 align-center px-1 pb-0 pt-0"},[_c('v-sheet',{staticClass:"dense-inputs"},[_c('div',{staticClass:"d-flex align-center flex-md-wrap"},[_c('v-autocomplete',{staticClass:"filter-width-200",attrs:{"items":_vm.filterItems.user,"loading":_vm.loading.filterItems.users,"placeholder":_vm.$t('message.user'),"autocomplete":"new-password","clearable":"","dense":"","hide-details":"auto","item-text":"User.title","item-value":"User.uuid","solo":""},on:{"change":function($event){return _vm.filterUserLog()}},model:{value:(_vm.filterValues.user),callback:function ($$v) {_vm.$set(_vm.filterValues, "user", $$v)},expression:"filterValues.user"}}),_c('v-spacer'),_c('v-btn',{staticClass:"px-2",attrs:{"loading":_vm.loading.clear},on:{"click":_vm.clearFilters}},[_vm._v(_vm._s(_vm.$t('message.clear')))])],1)]),_c('div',{staticStyle:{"position":"relative"}},[_c('v-overlay',{attrs:{"value":_vm.loading.logs,"absolute":"","opacity":"0.15"}},[_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"40","width":"6"}})],1)],1)],1),_c('v-data-table',{staticClass:"mt-3 appic-table-light specification-table",attrs:{"footer-props":{
                                    itemsPerPageOptions: _vm.itemsPerPageOptions,
                                    showFirstLastPage: true
                                },"headers":_vm.headers,"items":_vm.allRows,"options":_vm.tableOptions,"server-items-length":_vm.totalRows,"calculate-widths":"","dense":"","id":"userLogTable","item-key":"UserLog.id","sort-by":"UserLog.timestamp","sort-desc":""},on:{"update:options":_vm.updateDataTable},scopedSlots:_vm._u([{key:"item.UserLog.timestamp",fn:function(ref){
                                var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.UserLog.timestamp))])]}},{key:"item.UserLog.user",fn:function(ref){
                                var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold text-no-wrap"},[_vm._v(_vm._s(_vm.capitalize(item.UserLog.user)))])]}},{key:"item.UserLog.document",fn:function(ref){
                                var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.UserLog.document ? item.UserLog.document : _vm.$t('message.na')))])]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }